import type { ComputedRef } from "vue";
import { useI18n } from "vue-i18n";

export function useTranslate() {
  const { t: $t, locale } = useI18n();

  function translate(key: string, lang?: string | ComputedRef<string>, params?:Record<string, unknown>) {
    return $t(key, params || {}, { locale: (lang as any) || locale.value }, );
  }

  return {
    $t,
    translate
  }
}
